import React, { useState } from "react";
import LoginImg from "../../Assets/Rectangle 15.png";
import LoginImg1 from "../../Assets/Rectangle 15 (1).png";
import LogoSvg from "../../Assets/logo.svg";
import LogoSvg1 from "../../Assets/logo1.svg";
import "./style.css";
import Checkbox from "@mui/material/Checkbox";
import { IconButton, Box, Tabs, Tab } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../env";
import ErrorModal from "../../Components/ErrorModal/ErrorModal";
import PasswordModal from "../../Components/PasswordModal/PasswordModal";

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [value, setValue] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passModal, setPassModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCreateAccount = () => {
    navigate("/create-account");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleModalOpen = () => {
    setPassModal(true);
  };

  const handleModalClose = () => {
    setPassModal(false);
  };

  const handleErrorModalClose = () => {
    setErrorModal(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const url =
      value === 0
        ? `${API_URL}auth/token`
        : `${API_URL}auth/token/organization`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("token", data.access_token);
        navigate("/home");
      } else {
        setErrorModal(true);
      }
    } catch (error) {
      setErrorModal(true);
    }
  };

  return (
    <div className="login-main">
      <div className="login-img">
        <img src={LoginImg1} alt="login" className="image-desktop" />
        <img src={LoginImg} alt="login" className="image" />
      </div>
      <div className="login-div">
        <div className="welcome-div">
          <div className="welcome-container">
            <div className="welcome-head">Welcome to</div>
            <div className="image-div">
              <div className="welcome-head1">
                <img src={LogoSvg} alt="Logo" />
              </div>
              <div className="welcome-head1">
                <img src={LogoSvg1} alt="Logo" />
              </div>
            </div>
          </div>
          <div className="contain12">
            <Box sx={{ width: "100%" }}>
              <Tabs
                variant="fullWidth"
                value={value}
                onChange={handleChange}
                centered
              >
                <Tab
                  label="Organization"
                  sx={{
                    background:
                      value === 0
                        ? "linear-gradient(to right, #c1e1ec, #bfefff)"
                        : "transparent",
                    color: value === 0 ? "white" : "black",
                    borderRadius: "12px 0px 0px 0px",
                    transition: "all 0.3s ease",
                  }}
                />
                <Tab
                  label="User"
                  sx={{
                    background:
                      value === 1
                        ? "linear-gradient(to right, #c1e1ec, #bfefff)"
                        : "transparent",
                    color: value === 1 ? "white" : "black",
                    borderRadius: "0px 12px 0px 0px",
                    transition: "all 0.3s ease",
                  }}
                />
              </Tabs>
            </Box>
            <div className="contain1">
              <h2 className="txt8-login">Log In</h2>
              <form onSubmit={handleSubmit}>
                <div className="in-box-login">
                  <div className="in-box">
                    <div>
                      <label
                        className="label"
                        style={{ display: "block", marginBottom: 5 }}
                      >
                        Email
                      </label>
                      <input
                        className="inpt-login"
                        name="email"
                        type="text"
                        value={email}
                        onChange={handleEmailChange}
                        style={{
                          borderRadius: 12,
                          width: "100%",
                          padding: "10px",
                        }}
                      />
                    </div>
                    <div style={{ marginTop: 13, position: "relative" }}>
                      <label
                        className="label"
                        style={{ display: "block", marginBottom: 5 }}
                      >
                        Password
                      </label>
                      <input
                        className="inpt-login"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={handlePasswordChange}
                        style={{
                          borderRadius: 12,
                          width: "100%",
                          padding: "10px",
                        }}
                      />
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        style={{
                          position: "absolute",
                          right: 10,
                          top: "67%",
                          transform: "translateY(-50%)",
                        }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </div>
                  </div>
                  <div className="forgot-password-div">
                    <div>
                      <Checkbox
                        className="checkbox"
                        style={{ borderRadius: "4px" }}
                      />
                      Remember Me
                    </div>
                    <div className="forgot-password" onClick={handleModalOpen}>
                      Forgot Password?
                    </div>
                  </div>
                  <div className="auth-container">
                    <button type="submit" className="btn1-login">
                      Log In
                    </button>
                    <span className="or-divider">or</span>
                    <button
                      type="button"
                      className="btn1-create-account-1"
                      onClick={handleCreateAccount}
                    >
                      Create Account
                    </button>
                    <PasswordModal
                      isOpen={passModal}
                      onClose={handleModalClose}
                    />
                    {errorModal && (
                      <ErrorModal
                        isOpen={errorModal}
                        onClose={handleErrorModalClose}
                        message="Invalid Login Credentials"
                      />
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
