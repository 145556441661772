import React, { useState, useEffect } from "react";
import LoginImg from "../../Assets/Rectangle 15.png";
import LoginImg1 from "../../Assets/Rectangle 15 (1).png";
import LogoSvg from "../../Assets/logo.svg";
import LogoSvg1 from "../../Assets/logo1.svg";
import "./style.css";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { API_URL } from "../../env";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [submitError, setSubmitError] = useState("");

  useEffect(() => {
    // Get token from URL query parameters
    const urlToken = new URLSearchParams(location.search).get("token");

    setToken(urlToken);
    console.log(urlToken);
  }, []);

  const handleCreateAccount = () => {
    navigate("/");
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validatePassword = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasMinLength = password.length >= 8;

    if (!hasMinLength) {
      return "Password must be at least 8 characters long";
    }
    if (!hasUpperCase) {
      return "Password must contain at least one uppercase letter";
    }
    if (!hasNumber) {
      return "Password must contain at least one number";
    }
    if (!hasSpecialChar) {
      return "Password must contain at least one special character";
    }
    return "";
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setPasswordError(validatePassword(newPassword));

    if (confirmPassword) {
      setConfirmPasswordError(
        newPassword !== confirmPassword ? "Passwords do not match" : ""
      );
    }
  };

  const handleConfirmPasswordChange = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);
    setConfirmPasswordError(
      newConfirmPassword !== password ? "Passwords do not match" : ""
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitError("");

    // Validate password
    const passwordValidationError = validatePassword(password);
    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
      return;
    }

    // Validate password match
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      return;
    }

    try {
      const response = await fetch(`${API_URL}forgot_password/reset-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
          new_password: password,
          confirm_password: confirmPassword,
        }),
      });

      if (response.ok) {
        // Show success message or redirect
        navigate("/", {
          state: {
            message:
              "Password reset successful. Please login with your new password.",
          },
        });
      } else {
        const errorData = await response.json();
        setSubmitError(
          errorData.message || "Failed to reset password. Please try again."
        );
      }
    } catch (error) {
      setSubmitError("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="login-main">
      <div className="login-img">
        <img src={LoginImg1} alt="login" className="image-desktop" />
        <img src={LoginImg} alt="login" className="image" />
      </div>
      <div className="login-div">
        <div className="welcome-div">
          <div className="welcome-container">
            <div className="welcome-head">Welcome to</div>
            <div className="image-div">
              <div className="welcome-head1">
                <img src={LogoSvg} alt="Logo" />
              </div>
              <div className="welcome-head1">
                <img src={LogoSvg1} alt="Logo" />
              </div>
            </div>
          </div>
          <div className="reset-password-container">
            <div className="reset-password-container1">
              <h2 className="txt8-login">Create a New Password</h2>
              {/* <p
                style={{ textAlign: "center", color: "#6C7C93", marginTop: 0 }}
              >
                Password must contain at least 8 characters, one uppercase
                letter, one number, and one special character.
              </p> */}
              <form onSubmit={handleSubmit}>
                <div className="in-box-login">
                  <div className="in-box">
                    <div style={{ marginTop: 13 }}>
                      <label
                        className="label"
                        style={{ display: "block", marginBottom: 5 }}
                      >
                        New Password
                      </label>
                      <div style={{ position: "relative" }}>
                        <input
                          className="inpt-login"
                          name="password"
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={handlePasswordChange}
                          style={{
                            borderRadius: 12,
                            width: "100%",
                            padding: "10px",
                            borderColor: passwordError ? "#ff4d4f" : undefined,
                          }}
                        />
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          style={{
                            position: "absolute",
                            right: 10,
                            top: "50%",
                            transform: "translateY(-50%)",
                            zIndex: 1,
                          }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </div>
                      {passwordError && (
                        <div
                          style={{
                            color: "#ff4d4f",
                            fontSize: "12px",
                            marginTop: "4px",
                          }}
                        >
                          {passwordError}
                        </div>
                      )}
                    </div>
                    <div style={{ marginTop: 13 }}>
                      <label
                        className="label"
                        style={{ display: "block", marginBottom: 5 }}
                      >
                        Confirm Password
                      </label>
                      <div style={{ position: "relative" }}>
                        <input
                          className="inpt-login"
                          name="confirmPassword"
                          type={showConfirmPassword ? "text" : "password"}
                          value={confirmPassword}
                          onChange={handleConfirmPasswordChange}
                          style={{
                            borderRadius: 12,
                            width: "100%",
                            padding: "10px",
                            borderColor: confirmPasswordError
                              ? "#ff4d4f"
                              : undefined,
                          }}
                        />
                        <IconButton
                          onClick={handleToggleConfirmPasswordVisibility}
                          style={{
                            position: "absolute",
                            right: 10,
                            top: "50%",
                            transform: "translateY(-50%)",
                            zIndex: 1,
                          }}
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </div>
                      {confirmPasswordError && (
                        <div
                          style={{
                            color: "#ff4d4f",
                            fontSize: "12px",
                            marginTop: "4px",
                          }}
                        >
                          {confirmPasswordError}
                        </div>
                      )}
                    </div>
                  </div>
                  {submitError && (
                    <div
                      style={{
                        color: "#ff4d4f",
                        fontSize: "14px",
                        marginTop: "8px",
                        textAlign: "center",
                      }}
                    >
                      {submitError}
                    </div>
                  )}
                  <div className="auth-container" style={{ marginTop: 34 }}>
                    <button type="submit" className="btn1-login">
                      Reset Password
                    </button>
                    <span className="or-divider">or</span>
                    <button
                      type="button"
                      className="btn1-create-account"
                      onClick={handleCreateAccount}
                    >
                      Back to Login
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
