import React from 'react';
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import "./style.css";

function DetailModal({ isOpen, onClose, caseData, onSuccess }) {
    if (!caseData) {
        return <div>Loading...</div>;
    }
    console.log(caseData)
    const { CaseName, CaseDescription, HospitalName, FileSize, Status, Site,opinion } = caseData;

    if (!isOpen) return null; 

    const handleClose = () => {
        onClose();
    };

    return (
        <div
            className="modal-overlay1"
        >
            <div className="detail-content1">
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        top: -10,
                        right: -10,
                        width: 25,
                        height: 25,
                        borderRadius: "50%",
                        backgroundColor: "white",
                        color: "black",
                        padding: 0,
                        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                        cursor: 'pointer',
                    }}
                >
                    <CloseIcon />
                </IconButton>


                <h2 className="modal-title1" style={{marginBottom:"15px"}}>Case Info</h2>

                <div className="modal-content1">
                    <div className="modal-row1">
                        <p className="label1">Case Name</p>
                        <p className="value1" >{CaseName}</p>
                    </div>
                    <div className="modal-row1">
                        <p className="label1" style={{height:"25px"}}>Case Description</p>
                        <p className="value1" style={{height:"25px",marginTop:"13px"}}>{CaseDescription}</p>
                    </div>
                    <div className="modal-row1">
                        <p className="label1">Hospital Name</p>
                        <p className="value1">{HospitalName}</p>
                    </div>
                    <div className="modal-row1">
                        <p className="label1">Site</p>
                        <p className="value1">{Site}</p>
                    </div>
                    <div className="modal-row1">
                        <p className="label1">File Size</p>
                        <p className="value1">{FileSize?.toFixed(2)} MB</p>
                    </div> 
                    <div className="modal-row1">
                        <p className="label1">Status</p>
                        <p className="value1" style={{ color: "red"}}>{Status ? "True" : "False"}</p>
                        
                    </div>
                    <div className="modal-row1">
                        <p className="label1">Opinion</p>
                        <p className="value1">{opinion}</p>
                    </div>

                    </div>
                </div>
            </div>
            );
}

export default DetailModal;
