import React, { useEffect, useRef, useState } from "react";
import OpenSeadragon from "openseadragon";
import * as Annotorious from "@recogito/annotorious-openseadragon";
import "@recogito/annotorious-openseadragon/dist/annotorious.min.css";
import * as SelectorPack from "@recogito/annotorious-selector-pack";
import html2canvas from "html2canvas";
import "./style.css";
import { FitScreen } from "@mui/icons-material";
import { API_URL } from "../env";
import MicroscopeToolbar from "./MicroscopeToolbar";

const MicroscopeSlideViewer = ({
  currentImageUrl,
  setOpenWorkspace,
  openWorkspace,
  currentSlideId,
}) => {
  const [error, setError] = useState(null);
  const [annotations, setAnnotations] = useState({ annotations: [] });
  const currentStyleRef = useRef({ width: "1", color: "#FF0000" });
  const [currentTool, setCurrentTool] = useState(null);
  const [currentZoom, setCurrentZoom] = useState(0.5);
  const [lineWidth, setLineWidth] = useState("1");
  const [drawColor, setDrawColor] = useState("#FF0000");
  const [labelInput, setLabelInput] = useState("");
  const [rotation, setRotation] = useState(0);
  const [isCapturing, setIsCapturing] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const viewerRef = useRef(null);
  const annoRef = useRef(null);

  useEffect(() => {
    try {
      const viewer = OpenSeadragon({
        id: "viewer",
        prefixUrl: "https://openseadragon.github.io/openseadragon/images/",
        tileSources: `${API_URL}tile-server/s3-file/${currentImageUrl}`,
        showNavigator: true,
        navigatorPosition: "TOP_RIGHT",
        showZoomControl: true,
        showHomeControl: false,
        showFullPageControl: false,
        animationTime: 0.5,
        minZoomLevel: 0.1,
        maxZoomLevel: 40,
        crossOriginPolicy: "Anonymous",
        loadTilesWithAjax: true,
        homeFitBoundsPlacement: OpenSeadragon.Placement.CENTER,
        constrainDuringPan: true,
        visibilityRatio: 0.5,
        gestureSettingsMouse: {
          clickToZoom: false,
          dblClickToZoom: false,
          scrollToZoom: true,
          pinchToZoom: true,
        },
        ajaxHeaders: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      viewerRef.current = viewer;

      viewer.addHandler("open", function () {
        initializeAnnotorious(viewer);
        updateStrokeStyle(lineWidth, drawColor);
        loadAnnotations();
      });

      return () => {
        if (viewerRef.current) {
          viewerRef.current.destroy();
        }
      };
    } catch (error) {
      console.error("Failed to initialize viewer:", error);
      setError(
        "Failed to initialize the viewer. Please check the console for details."
      );
    }
  }, [currentImageUrl]);

  const initializeAnnotorious = (viewer) => {
    const styleFormatter = (annotation) => {
      console.log(annotation)
      const styleBody =
        annotation.bodies?.find((body) => body.purpose === "style") ||
        annotation.body?.find((body) => body.purpose === "style") ||
        annotation.underlying?.bodies?.find((body) => body.purpose === "style") ||
        annotation.underlying?.body?.find((body) => body.purpose === "style")

      if (styleBody) {
        const [width, color] = styleBody.value.split("-");
        // Update current style to match annotation
        currentStyleRef.current = { width, color };
        setLineWidth(width);
        setDrawColor(color);

        return {
          style: `stroke-width:${width}; stroke: ${color}`,
        };
      }

      return {
        style: `stroke-width:${currentStyleRef.current.width}; stroke: ${currentStyleRef.current.color}`,
      };
    };

    const anno = Annotorious(viewer, {
      formatters: [styleFormatter],
      drawingEnabled: false, // Changed to false so no tool is initially enabled
      allowEmpty: true,
      immediate: true,
    });

    SelectorPack.default(anno, {
      tools: [
        "rect",
        "polygon",
        "point",
        "circle",
        "ellipse",
        "freehand",
        "line",
      ],
    });

    // Removed the automatic tool selection
    setCurrentTool(null); // Set to null initially

    // When finishing a drawing
    anno.on("createAnnotation", (annotation) => {
      if (!annotation.bodies) {
        annotation.bodies = [];
      }

      const hasStyle = annotation.bodies.some(
        (body) => body.purpose === "style"
      );
      if (!hasStyle) {
        annotation.bodies.push({
          type: "TextualBody",
          purpose: "style",
          value: `${
            currentStyleRef.current.width
          }-${currentStyleRef.current.color.toLowerCase()}`,
        });
      }

      saveAnnotations();
    });

    // When updating an annotation
    anno.on("updateAnnotation", (annotation, previous) => {
      saveAnnotations();
    });

    anno.on("deleteAnnotation", (annotation) => {
      saveAnnotations();
    });

    annoRef.current = anno;
  };
  const updateStrokeStyle = (width, color) => {
    currentStyleRef.current = {
      width: width,
      color: color,
    };

    // If there's a selected annotation, update its style
    if (annoRef.current) {
      const selected = annoRef.current.getSelected();
      if (selected) {
        if (!selected.bodies) {
          selected.bodies = [];
        }

        selected.bodies = selected.bodies.filter(
          (body) => body.purpose !== "style"
        );
        selected.bodies.push({
          type: "TextualBody",
          purpose: "style",
          value: `${width}-${color.toLowerCase()}`,
        });

        // Update the annotation and save to API
        annoRef.current.updateSelected(selected);
        saveAnnotations();
      }
    }
  };

  const updateStrokeColor = (color) => {
    setDrawColor(color);
    updateStrokeStyle(lineWidth, color);
  };

  const updateStrokeWidth = (width) => {
    setLineWidth(width);
    updateStrokeStyle(width, drawColor);
  };

  const loadAnnotations = async () => {
    try {
      const response = await fetch(`${API_URL}annotation/${currentSlideId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const data = await response.json();

      setAnnotations({ annotations: data.annotations });
      annoRef.current.setAnnotations(data.annotations);
    } catch (error) {
      console.error("Error loading annotations:", error);
      setError("Failed to fetch annotations. Please try again later.");
    }
  };

  const saveAnnotations = () => {
    const annotationsToSave = {
      annotations: annoRef.current.getAnnotations(),
      slide_id: currentSlideId,
    };

    fetch(`${API_URL}annotation/${currentSlideId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(annotationsToSave),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Annotations saved successfully:", data);
      })
      .catch((error) => {
        console.error("Error saving annotations:", error);
        setError("Failed to save annotations. Changes may be lost.");
      });
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange
      );
    };
  }, []);

  const rotateLeft = () => {
    if (!viewerRef.current) return;
    const newRotation = (rotation - 90) % 360;
    setRotation(newRotation);
    viewerRef.current.viewport.setRotation(newRotation);
  };

  const rotateRight = () => {
    if (!viewerRef.current) return;
    const newRotation = (rotation + 90) % 360;
    setRotation(newRotation);
    viewerRef.current.viewport.setRotation(newRotation);
  };

  const handlePreciseRotation = (degrees) => {
    if (!viewerRef.current) return;
    const newRotation = (rotation + degrees) % 360;
    setRotation(newRotation);
    viewerRef.current.viewport.setRotation(newRotation);
  };

  const toggleFullscreen = () => {
    const viewerElement = document.querySelector(".microscope-slide-viewer");

    if (!document.fullscreenElement) {
      if (viewerElement.requestFullscreen) {
        viewerElement.requestFullscreen();
      } else if (viewerElement.webkitRequestFullscreen) {
        viewerElement.webkitRequestFullscreen();
      } else if (viewerElement.msRequestFullscreen) {
        viewerElement.msRequestFullscreen();
      }
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      setIsFullscreen(false);
    }
  };

  const toggleGrid = () => {
    const gridElement = document.querySelector("[data-id='grid']");
    if (gridElement) {
      viewerRef.current.removeOverlay(gridElement);
      return;
    }

    var viewerWidth = viewerRef.current.world.getItemAt(0).source.width;
    var viewerHeight = viewerRef.current.world.getItemAt(0).source.height;
    const gridSize = viewerWidth / 10;

    var numHorizontalLines = Math.ceil(viewerHeight / gridSize);
    var numVerticalLines = Math.ceil(viewerWidth / gridSize);

    let element = document.createElement("div");
    element.style.position = "absolute";
    element.style.pointerEvents = "none";
    element.dataset.id = "grid";
    element.style.width = viewerWidth + "px";
    element.style.height = viewerHeight + "px";

    let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute("width", "100%");
    svg.setAttribute("height", "100%");
    svg.setAttribute("preserveAspectRatio", "none");
    svg.setAttribute("viewBox", `0 0 ${viewerWidth} ${viewerHeight}`);

    for (var i = 0; i <= numHorizontalLines; i++) {
      var line = document.createElementNS("http://www.w3.org/2000/svg", "line");
      line.setAttribute("x1", "0");
      line.setAttribute("y1", i * gridSize);
      line.setAttribute("x2", viewerWidth);
      line.setAttribute("y2", i * gridSize);
      line.setAttribute("stroke", "rgba(0, 0, 0, 0.5)");
      line.setAttribute("stroke-width", "1");
      line.setAttribute("vector-effect", "non-scaling-stroke");
      svg.appendChild(line);
    }

    for (var i = 0; i <= numVerticalLines; i++) {
      var line = document.createElementNS("http://www.w3.org/2000/svg", "line");
      line.setAttribute("x1", i * gridSize);
      line.setAttribute("y1", "0");
      line.setAttribute("x2", i * gridSize);
      line.setAttribute("y2", viewerHeight);
      line.setAttribute("stroke", "rgba(0, 0, 0, 0.5)");
      line.setAttribute("stroke-width", "1");
      line.setAttribute("vector-effect", "non-scaling-stroke");
      svg.appendChild(line);
    }

    element.appendChild(svg);

    let rect = new OpenSeadragon.Rect(0, 0, viewerWidth, viewerHeight);
    let viewportRect =
      viewerRef.current.viewport.imageToViewportRectangle(rect);
    viewerRef.current.addOverlay({
      element: element,
      location: viewportRect,
      placement: OpenSeadragon.Placement.TOP_LEFT,
    });
  };

  const captureSnapshot = async () => {
    if (!viewerRef.current || isCapturing) return;

    setIsCapturing(true);
    const element = viewerRef.current.canvas;
    html2canvas(element).then((canvas) => {
      const dataURL = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
      const filename = `microscope-snapshot-${timestamp}.png`;
      link.href = dataURL;
      link.download = filename;
      link.click();
      setIsCapturing(false);
    });
  };

  const prepareAndCaptureSnapshot = () => {
    if (!viewerRef.current || isCapturing) return;
    viewerRef.current.forceRedraw();
    setTimeout(() => {
      captureSnapshot();
    }, 200);
  };

  const eraseAllAnnotations = () => {
    if (viewerRef.current && annoRef.current) {
      annoRef.current.clearAnnotations();
      saveAnnotations();
    }
  };

  const handleZoom = (zoomLevel) => {
    if (!viewerRef.current) return;

    const viewer = viewerRef.current;
    const viewport = viewer.viewport;

    // Handle special zoom cases
    if (zoomLevel === 0.5) {
      // Fit to screen
      viewport.goHome();
      setCurrentZoom(0.5);
      return;
    }

    // Get the image dimensions
    const image = viewer.world.getItemAt(0);
    if (!image) return;

    const imageBounds = image.getBounds();
    const containerSize = viewer.viewport.getContainerSize();

    // Calculate the base zoom level that makes the image fit the screen
    const horizontalZoom = containerSize.x / imageBounds.width;
    const verticalZoom = containerSize.y / imageBounds.height;
    const baseZoom = Math.min(horizontalZoom, verticalZoom);

    // Calculate the actual zoom level based on the desired magnification
    let targetZoom;
    switch (zoomLevel) {
      case 0.5: // Fit to screen
        targetZoom = baseZoom;
        break;
      case 1: // 2x
        targetZoom = 2;
        break;
      case 2.5: // 5x
        targetZoom = 5;
        break;
      case 5: // 10x
        targetZoom = 10;
        break;
      case 10: // 20x
        targetZoom = 20;
        break;
      case 20: // 40x
        targetZoom = 40;
        break;
      case 30: // 60x
        targetZoom = 60;
        break;
      default:
        targetZoom = zoomLevel;
    }

    // Get current center
    const center = viewport.getCenter();

    // Apply zoom with animation
    viewport.zoomTo(targetZoom, center, true);

    // Update current zoom state
    setCurrentZoom(zoomLevel);
  };

  const handleSetCurrentTool = (tool) => {
    setCurrentTool(tool);
    annoRef.current.setDrawingTool(tool);
    annoRef.current.setDrawingEnabled(true);
  };

  return (
    <>
      <div
        className={`microscope-slide-viewer ${
          isFullscreen ? "fullscreen" : ""
        }`}
      >
        <MicroscopeToolbar
          currentTool={currentTool}
          setCurrentTool={handleSetCurrentTool}
          openWorkspace={openWorkspace}
          setOpenWorkspace={setOpenWorkspace}
          lineWidth={lineWidth}
          setLineWidth={updateStrokeWidth}
          drawColor={drawColor}
          setDrawColor={updateStrokeColor}
          labelInput={labelInput}
          setLabelInput={setLabelInput}
          rotation={rotation}
          handlePreciseRotation={handlePreciseRotation}
          isFullscreen={isFullscreen}
          onSnapshot={prepareAndCaptureSnapshot}
          onToggleGrid={toggleGrid}
          onClearAnnotations={eraseAllAnnotations}
          onToggleFullscreen={toggleFullscreen}
          onRotateLeft={rotateLeft}
          onRotateRight={rotateRight}
        />

        <div className="viewer-container">
          <div
            id="viewer"
            style={{ width: "100%", height: isFullscreen ? "100vh" : "647px" }}
            className="openseadragon-viewer"
          ></div>
          {error && <div className="error-message">{error}</div>}
          {isCapturing && (
            <div className="capturing-overlay">Capturing snapshot...</div>
          )}
        </div>
        <div className="controls">
          <div className="zoom-controls">
            <button
              className={`button-zoom ${currentZoom === 0.5 ? "selected" : ""}`}
              onClick={() => handleZoom(0.5)}
            >
              <FitScreen />
            </button>
            <button
              className={`button-zoom ${currentZoom === 1 ? "selected" : ""}`}
              onClick={() => handleZoom(1)}
              title="2x Magnification"
            >
              2x
            </button>
            <button
              className={`button-zoom ${currentZoom === 2.5 ? "selected" : ""}`}
              onClick={() => handleZoom(2.5)}
              title="5x Magnification"
            >
              5x
            </button>
            <button
              className={`button-zoom ${currentZoom === 5 ? "selected" : ""}`}
              onClick={() => handleZoom(5)}
              title="10x Magnification"
            >
              10x
            </button>
            <button
              className={`button-zoom ${currentZoom === 10 ? "selected" : ""}`}
              onClick={() => handleZoom(10)}
              title="20x Magnification"
            >
              20x
            </button>
            <button
              className={`button-zoom ${currentZoom === 20 ? "selected" : ""}`}
              onClick={() => handleZoom(20)}
              title="40x Magnification"
            >
              40x
            </button>
            <button
              className={`button-zoom ${currentZoom === 30 ? "selected" : ""}`}
              onClick={() => handleZoom(30)}
              title="60x Magnification"
            >
              60x
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MicroscopeSlideViewer;
