import React from "react";
import "./style.css";

const Demo = () => {
  return (
    <div className="demo-container">
      <div className="demo-content">
        <div className="demo-text">
          <p className="demo-title">Demo Version</p>
          <p className="demo-description">
            This feature is currently unavailable. Please explore the full
            version for complete functionality.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Demo;
