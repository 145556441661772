import React, { useState, useEffect } from "react";
import LogoSvg from "../../Assets/nav-logo.svg";
import LogoSvg1 from "../../Assets/nav-logo1.svg";
import LogoSvg2 from "../../Assets/Ellipse 1.png";
import "./style.css";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ProfileModal from "../ProfileModal/ProfileModal";
import { API_URL } from "../../env";

const Navbar = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${API_URL}users`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        const data = await response.json();
        setUserData(data);
      } catch (err) {
        setError(err.message);
        console.error("Error fetching user data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handlerProfileModalOpen = (e) => {
    const rect = e.target.getBoundingClientRect();
    setModalPosition({
      top: rect.bottom + window.scrollY + 10,
      left: rect.left + window.scrollX,
    });
    setIsModalVisible(true);
  };

  const handleProfileModalClose = () => {
    setIsModalVisible(false);
  };

  const formatStorageUsed = (bytes) => {
    const gb = bytes / 1024;
    return `${gb.toFixed(1)}`;
  };

  if (loading) {
    return <div className="navbar-container">Loading...</div>;
  }

  if (error) {
    return <div className="navbar-container">Error: {error}</div>;
  }

  return (
    <div>
      <div className="navbar-container">
        <div className="navbar-div">
          <div className="nav-image">
            <img src={LogoSvg} alt="nav-logo" className="nav-logo" />
          </div>
          <div className="nav-img">
            <img src={LogoSvg1} alt="nav-logo" className="nav-logo" />
          </div>
        </div>
        <div className="nav-item">
          <div
            className="message-icon"
            style={{
              color: "white",
              border: "2px solid white",
              borderRadius: "50%",
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "20px",
            }}
          >
            <ChatBubbleOutlineOutlinedIcon />
          </div>
          <div className="nav-space" style={{ marginRight: "5px" }}>
            <div className="nav">{userData?.CaseStats?.TotalCases || 0}</div>
            <div className="nav-total">Total Case</div>
          </div>
          <div className="nav-space">
            <div className="nav">
              {userData
                ? `${formatStorageUsed(
                    userData.CaseStats.TotalFileSize
                  )}GB/${formatStorageUsed(userData.StorageUsed)}GB `
                : "0/50"}
            </div>
            <div className="nav-total">Total Space Used</div>
          </div>
          <div className="avatar">
            <img
              className="avtimage"
              src={LogoSvg2}
              alt="User avatar"
              onClick={handlerProfileModalOpen}
            />
            <div className="avatar-info">
              <div className="avatar-name">{userData?.FullName || "User"}</div>
              <div className="avatar-email">
                {userData?.Email || "loading..."}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalVisible && (
        <ProfileModal
          handleClose={handleProfileModalClose}
          modalPosition={modalPosition}
          userData={userData}
        />
      )}
    </div>
  );
};

export default Navbar;
