import React, { useState } from "react";
import "./style.css";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import VerifiedIcon from "@mui/icons-material/VerifiedOutlined";
import { API_URL } from "../../env";

function PasswordModal({ isOpen, onClose }) {
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleClose = () => {
    setStep(1);
    setEmail("");
    setError("");
    onClose();
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    console.log("1. Function started");
    console.log("2. Email value:", email);
    setError("");

    if (!validateEmail(email)) {
      console.log("3. Email validation failed");
      setError("Please enter a valid email address.");
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch(`${API_URL}forgot_password/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      console.log("5. Fetch completed", response);
      const data = await response.json();
      console.log("6. Response data:", data);

      if (!response.ok) {
        throw new Error(data.message || "Failed to send reset email");
      }

      setStep(2);
    } catch (err) {
      console.error("7. Error occurred:", err);
      setError(err.message || "An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="password-main">
        <div className="password-modal">
          <div className="modal-header">
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: -10,
                right: -10,
                width: 25,
                height: 25,
                borderRadius: "50%",
                backgroundColor: "white",
                color: "black",
                padding: 0,
                boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
              }}
            >
              <CloseIcon />
            </IconButton>

            {error && <div className="error-message">{error}</div>}

            {step === 1 && (
              <>
                <h2 className="title">Reset Password</h2>
                <p className="para1">
                  Enter your email and we'll send you a link to reset your
                  password.
                </p>
                <div className="form-content">
                  <label htmlFor="email" className="email-label">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Enter your email here..."
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    className="email-input"
                    disabled={isSubmitting}
                    required
                  />
                  <button
                    type="button"
                    onClick={handleClose}
                    className="cancel-button1"
                    disabled={isSubmitting}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="submit-button1"
                    disabled={isSubmitting || !email}
                    onClick={handleSubmit}
                  >
                    {isSubmitting ? "Sending..." : "Submit"}
                  </button>
                </div>
              </>
            )}

            {step === 2 && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "70px",
                      height: "70px",
                      backgroundColor: "white",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                    }}
                  >
                    <VerifiedIcon
                      sx={{
                        color: "#64e764",
                        fontSize: "44px",
                      }}
                    />
                  </div>
                </div>
                <h2 className="title">Email Sent</h2>
                <p className="para1">
                  Check your email and open the link we sent to continue
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordModal;
