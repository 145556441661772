import React from "react";
import ColorPicker from "./ColorPicker";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Pencil from "@mui/icons-material/Create";
import {
  RectangleOutlined,
  Grid4x4Outlined,
  DeleteOutline,
  PolylineOutlined,
  FitScreen,
  PhotoCamera,
  RotateLeftOutlined,
  RotateRightOutlined,
  EditNote,
  Gesture,
} from "@mui/icons-material";
import { Circle, Minus, Maximize2, Minimize2 } from "lucide-react";
const ToolButton = ({ onClick, title, selected, children }) => (
  <div className="tool-button-wrapper">
    <div
      className={`tool-button ${selected ? "selected" : ""}`}
      onClick={onClick}
    >
      {children}
    </div>
    <div className="tooltip">{title}</div>
  </div>
);

// Toolbar Component
const MicroscopeToolbar = ({
  setCurrentTool,
  currentTool,
  openWorkspace,
  setOpenWorkspace,
  lineWidth,
  setLineWidth,
  drawColor,
  setDrawColor,
  rotation,
  handlePreciseRotation,
  isFullscreen,
  onSnapshot,
  onToggleGrid,
  onClearAnnotations,
  onToggleFullscreen,
  onRotateLeft,
  onRotateRight,
}) => {
  return (
    <div className="toolbar">
      {/* Navigation */}
      <div className="toolbar-group">
        <ToolButton
          onClick={() => setOpenWorkspace(!openWorkspace)}
          title="Toggle Workspace"
        >
          <ArrowBack />
        </ToolButton>
      </div>

      <div className="toolbar-separator" />

      {/* Drawing Tools */}
      <div className="toolbar-group">
        <ToolButton
          onClick={() => setCurrentTool("rect")}
          selected={currentTool === "rect"}
          title="Rectangle Tool"
        >
          <RectangleOutlined />
        </ToolButton>
        <ToolButton
          onClick={() => setCurrentTool("circle")}
          selected={currentTool === "circle"}
          title="Circle Tool"
        >
          <Circle />
        </ToolButton>
        <ToolButton
          onClick={() => setCurrentTool("freehand")}
          selected={currentTool === "freehand"}
          title="Brush Tool"
        >
          <Gesture />
        </ToolButton>
        <ToolButton
          onClick={() => setCurrentTool("line")}
          selected={currentTool === "line"}
          title="Line Tool"
        >
          <Minus />
        </ToolButton>
        <ToolButton
          onClick={() => setCurrentTool("polygon")}
          selected={currentTool === "polygon"}
          title="Polygon Tool"
        >
          <PolylineOutlined />
        </ToolButton>
      </div>

      <div className="toolbar-separator" />

      {/* Style Controls */}
      <div className="toolbar-group">
        <div className="style-control">
          <label>Width:</label>
          <select
            value={lineWidth}
            onChange={(e) => setLineWidth(e.target.value)}
          >
            <option value="1">Extra thin</option>
            <option value="2">Thin</option>
            <option value="3">Normal</option>
            <option value="4">Thick</option>
          </select>
        </div>

        <div className="style-control">
          <ColorPicker selectedColor={drawColor} onColorChange={setDrawColor} />
        </div>
      </div>

      <div className="toolbar-separator" />

      {/* Rotation Controls */}
      <div className="toolbar-group">
        <ToolButton onClick={onRotateLeft} title="Rotate Left 90°">
          <RotateLeftOutlined />
        </ToolButton>
        <ToolButton onClick={onRotateRight} title="Rotate Right 90°">
          <RotateRightOutlined />
        </ToolButton>
        <div className="style-control">
          <label>Rotation:</label>
          <input
            type="number"
            min="0"
            max="360"
            step="1"
            value={rotation}
            onChange={(e) => {
              const degrees = parseFloat(e.target.value) - rotation;
              handlePreciseRotation(degrees);
            }}
            style={{ width: "40px" }}
          />
          <span>°</span>
        </div>
      </div>

      <div className="toolbar-separator" />

      {/* Utility Tools */}
      <div className="toolbar-group">
        <ToolButton onClick={onSnapshot} title="Capture Snapshot">
          <PhotoCamera />
        </ToolButton>
        <ToolButton onClick={onToggleGrid} title="Toggle Grid">
          <Grid4x4Outlined />
        </ToolButton>
        <ToolButton onClick={onClearAnnotations} title="Clear All Annotations">
          <DeleteOutline />
        </ToolButton>
        <ToolButton
          onClick={onToggleFullscreen}
          title={isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen"}
        >
          {isFullscreen ? <Minimize2 /> : <Maximize2 />}
        </ToolButton>
      </div>
    </div>
  );
};
export default MicroscopeToolbar;
