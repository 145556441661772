import React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import "./style.css";

function ErrorModal({ isOpen, onClose,message }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <div
      className="error-modal-overlay"
      onClick={(e) => {
        if (e.target.className === "error-modal-overlay") handleClose();
      }}
    >
      <div className="error-modal">
        <div className="modal-header">
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: -10,
              right: -10,
              width: 25,
              height: 25,
              borderRadius: "50%",
              backgroundColor: "white",
              color: "black",
              padding: 0,
              boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
            }}
          >
            <CloseIcon />
          </IconButton>

          <div
            style={{
              width: "60px",
              height: "60px",
              backgroundColor: "white",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              margin: "0 auto",
              marginBottom: "40px",
              padding:"14px"
            }}
          >
            <ErrorOutlineIcon
              sx={{
                color: "red",
                fontSize: "50px",
              }}
            />
          </div>

          <h2 className="title">{message || "Something went Wrong"} </h2>
          <button type="button" onClick={handleClose} className="title-button1">
            Try Again
          </button>
        </div>
      </div>
    </div>
  );
}

export default ErrorModal;
