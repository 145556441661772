import React from "react";
import { useLocation } from "react-router-dom";
import ShareViewer from "./ShareViewer";

const ViewerPage = () => {
  const location = useLocation();
  const { annotationUrl, viewerUrl, slideName, isEditMode } =
    location.state || {};

  // Fallback to localStorage if state is not available (e.g., on page refresh)
  const annotationUrlFinal =
    annotationUrl || localStorage.getItem("annotationUrl");
  const viewerUrlFinal = viewerUrl || localStorage.getItem("viewerUrl");
  const slideNameFinal = slideName || localStorage.getItem("slideName");
  const isEditModeFinal =
    isEditMode ?? localStorage.getItem("isEditMode") === "true";

  return (
    <div className="viewer-page">
      <ShareViewer
        currentImageUrl={viewerUrlFinal}
        currentSlideId={annotationUrlFinal.split("/").pop()} // Extract ID from URL
        annotationUrl={annotationUrlFinal}
        isEditMode={isEditModeFinal}
        setOpenWorkspace={() => {}} // Implement as needed
        openWorkspace={false}
      />
    </div>
  );
};

export default ViewerPage;
