import React, { useState, useRef, useEffect } from "react";

const ColorPicker = ({ selectedColor, onColorChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const colors = [
    // Row 1
    "#000000",
    "#7F7F7F",
    "#FF0000",
    "#FF4E11",
    "#FF9500",
    "#FFFF00",
    "#00FF00",
    "#00FFFF",
    "#0000FF",
    "#9C27B0",
    "#FF1493",
    // Row 2
    "#FFB6C1",
    "#FFC0CB",
    "#FFE4E1",
    "#FFE5B4",
    "#FFFACD",
    "#F0FFF0",
    "#E0FFFF",
    "#F0F8FF",
    "#E6E6FA",
    "#D8BFD8",
    "#FFF0F5",
    // Row 3
    "#8B0000",
    "#A52A2A",
    "#B8860B",
    "#006400",
    "#008080",
    "#000080",
    "#4B0082",
    "#800080",
    "#8B4513",
    "#A0522D",
    "#D2691E",
  ];

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleColorSelect = (color) => {
    onColorChange(color);
    setIsOpen(false);
  };

  const styles = `
    .color-picker-container {
      position: relative;
      display: inline-block;
    }

    .color-button {
      padding: 8px;
      border: none;
      border-radius: 4px;
      background: #ffffff00;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .color-preview {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      border: 1px solid #ccc;
    }

    .color-dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      margin-top: 4px;
      background: white;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 10px;
      box-shadow: 0 2px 5px rgba(0,0,0,0.2);
      display: grid;
      grid-template-columns: repeat(11, 1fr);
      gap: 4px;
      width: 300px;
      z-index: 1000;
    }

    .color-option {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border: 1px solid #ccc;
      cursor: pointer;
      transition: transform 0.2s;
    }

    .color-option:hover {
      transform: scale(1.2);
      border-color: #666;
    }
  `;

  return (
    <>
      <style>{styles}</style>
      <div className="color-picker-container" ref={dropdownRef}>
        <button className="color-button" onClick={() => setIsOpen(!isOpen)}>
          <div
            className="color-preview"
            style={{ backgroundColor: selectedColor }}
          />
        </button>

        {isOpen && (
          <div className="color-dropdown">
            {colors.map((color, index) => (
              <div
                key={index}
                className="color-option"
                style={{ backgroundColor: color }}
                onClick={() => handleColorSelect(color)}
                title={color}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ColorPicker;
