import React from 'react'
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import './style.css'

function SuccessModal({ isOpen, onClose }) {
    const handleClose = () => {
        onClose();
    };
    const navigate = useNavigate();

    return (
        <div className="success-modal-overlay" onClick={(e) => { if (e.target.className === "sucess-modal-overlay") handleClose(); }}>
            <div className='success-modal'>
                <div className='modal-header'>
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            top: -10,
                            right: -10,
                            width: 25,
                            height: 25,
                            borderRadius: '50%',
                            backgroundColor: 'white',
                            color: 'black',
                            padding: 0,
                            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <div style={{
                        width: "70px",
                        height: "70px",
                        backgroundColor: "white",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        margin: "0 auto",
                        marginBottom: "40px"
                    }}>
                        <VerifiedOutlinedIcon sx={{
                            color: "#AEF359",
                            fontSize: "44px",
                        }} />
                    </div>

                    <h2 className="title">Password Rest Successfully</h2>
                    <button
                        type="button"
                        onClick={() => navigate("/")}
                        className="title-button1"

                    >
                        Log In
                    </button>

                </div>
            </div>
        </div>
    )
}

export default SuccessModal
