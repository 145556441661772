import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const URLRedirectHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAndRedirect = () => {
      const currentURL = window.location.href;

      // Check for shared link
      if (currentURL.includes("pathlite.genesysailabs.com/share/")) {
        const shareId = currentURL.split("/share/")[1];
        const urlParams = new URLSearchParams(window.location.search);
        const isPublic = urlParams.get("isPublic") === "True";

        // Redirect to viewer route if isPublic is True, otherwise to sharelogin
        if (isPublic) {
          navigate(`/viewer?id=${shareId}`);
        } else {
          navigate(`/sharelogin?id=${shareId}`);
        }
        return;
      }

      // Check for reset password link
      if (currentURL.includes("pathlite.genesysailabs.com/reset-password")) {
        const token = new URLSearchParams(window.location.search).get("token");
        if (token) {
          navigate("/reset-password", {
            state: {
              showResetPassword: true,
              resetToken: token,
            },
          });
        }
      }
    };

    checkAndRedirect();
  }, [navigate, location]);

  return null;
};

export default URLRedirectHandler;
