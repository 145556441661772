import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import "./style.css";
import CaseModal from "../../Components/CaseModal/CaseModal";
import {
  AddCommentOutlined,
  Delete,
  DeleteOutline,
  EditOutlined,
  FlagOutlined,
  RemoveRedEyeOutlined,
  Search,
  ShareOutlined,
  VerifiedOutlined,
} from "@mui/icons-material";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Checkbox,
  Pagination,
  Stack,
  PaginationItem,
  Button,
} from "@mui/material";
import Swal from "sweetalert2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SlideArchive from "../Slide/SlideArchive";

import { API_URL } from "../../env";
import ShareModal from "../../Components/Share/ShareModal";
import FlagModal from "../../Components/Flag/FlagModal";
import OpinionModal from "../../Components/Opinion/OpinionModal";
import DetailModal from "../../Components/DetailModal/DetailModal";
import ErrorModal from "../../Components/ErrorModal/ErrorModal";
import SuccessModal from "../../Components/SucessModal/SuccessModal";

const WorkList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [cases, setCases] = useState([]);
  const [open, setOpen] = useState(false);
  const [openWorklist, setopenWorklist] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [selectedCases, setSelectedCases] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentCaseId, setCurrentCaseId] = useState(null);
  const [isDeleteActive, setIsDeleteActive] = useState(false);
  const [isFlagModalOpen, setIsFlagModalOpen] = useState(false);
  const [selectedCaseForFlag, setSelectedCaseForFlag] = useState(null);
  const [isOpinionModalOpen, setIsOpinionModalOpen] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [caseData, setCaseData] = useState(null);
  const [SelectedCaseDetail, setSelectedCaseDetail] = useState(null);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  // const [users, setUsers] = useState(null);
  const [isErrorModal, setIsErrorModal] = useState(false);

  const handleBatchDelete = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete multiple cases. This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete them!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Changed the endpoint to use a dedicated batch delete endpoint
          const response = await fetch(`${API_URL}cases/`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              case_ids: selectedCases.map((id) => parseInt(id, 10)),
            }),
          });

          if (response.ok) {
            Swal.fire(
              "Deleted!",
              "The selected cases have been deleted.",
              "success"
            );
            setSelectedCases([]);
            setIsDeleteActive(false);
            setRefresh(!refresh);
          } else {
            const errorData = await response.json();
            throw new Error(errorData.detail || "Failed to delete cases");
          }
        } catch (error) {
          console.error("Error deleting cases:", error);
          Swal.fire(
            "Error",
            "An error occurred while deleting the cases.",
            "error"
          );
        }
      }
    });
  };

  const getPriorityLabel = (value) => {
    let label = "Unknown";
    let style = {};

    switch (value) {
      case "1":
        label = "Low";
        style = {
          color: "var(--Green-100, #47D273)",
          fontSize: "14px",
          fontWeight: 600,
          borderRadius: "4px",
          background: "var(--Green-5, #EBFAEF)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 68,
          height: 28,
        };
        break;
      case "2":
        label = "Medium";
        style = {
          color: "var(--Yellow-100, #FAC20A)",
          fontSize: "14px",
          fontWeight: 600,
          borderRadius: "4px",
          background: "var(--Yellow-5, #FEF9E6)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 68,
          height: 28,
        };
        break;
      case "3":
        label = "High";
        style = {
          color: "var(--Red-100, #FB3B42)",
          fontSize: "14px",
          fontWeight: 600,
          borderRadius: "4px",
          background: "var(--Red-5, #FEE6E7)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 68,
          height: 28,
        };
        break;
      default:
        break;
    }

    return { label, style };
  };

  const deleteCase = async (caseId) => {
    try {
      const response = await fetch(`${API_URL}cases/${caseId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.ok) {
        const result = await response.json();
        setRefresh(true);
        return true;
      } else {
        console.error("Failed to delete case");
        return false;
      }
    } catch (error) {
      console.error("Error deleting case:", error);
      return false;
    }
  };

  const handleDeleteCase = async (caseId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const isDeleted = await deleteCase(caseId);
        if (isDeleted) {
          Swal.fire("Deleted!", "Your case has been deleted.", "success");
        }
        setRefresh(!refresh);
      }
    });
  };

  useEffect(() => {
    fetch(`${API_URL}cases/?page=${page}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCases(data.cases);
        setTotalPages(data.total_pages);
      })
      .catch((error) => {
        console.error("Error fetching cases:", error);
      });
  }, [refresh, page]);
  const handleFlagClose = () => {
    setIsFlagModalOpen(false);
    setSelectedCaseForFlag(null);
  };

  const handleFlagOpen = (caseId) => {
    const caseToOpen = cases.find((c) => c.CaseId === caseId);
    if (caseToOpen) {
      setSelectedCaseForFlag(caseToOpen);
      setIsFlagModalOpen(true);
    }
  };

  const handleOpinionClose = () => {
    setIsOpinionModalOpen(false);
    setSelectedCase(null);
  };

  const handleOpinionOpen = (caseId) => {
    const caseToOpen = cases.find((c) => c.CaseId === caseId);
    if (caseToOpen) {
      setSelectedCase(caseToOpen);
      setIsOpinionModalOpen(true);
    }
  };
  const handleDetailOpen = (caseId) => {
    const caseToOpen = cases.find((c) => c.CaseId === caseId);
    if (caseToOpen) {
      setSelectedCaseDetail(caseToOpen);
      setIsDetailModalOpen(true);
    }
  };
  const handleDetailClose = () => {
    setSelectedCaseDetail(null);
    setIsDetailModalOpen(false);
  };
  const handleOpenCreate = () => {
    setIsEditMode(false);
    setCaseData(null);
    setOpen(true);
  };

  const handleOpenEdit = (caseId) => {
    setIsEditMode(true);
    const selectedCase = cases.find((item) => item.CaseId === caseId);
    console.log(selectedCase);
    setCaseData(selectedCase);
    setOpen(true);
  };

  const handleSave = () => {
    setRefresh((prev) => !prev);
    setOpen(false);
  };

  const handleErroOpen = () => {
    setIsErrorModal(true);
    setOpen(true);
  };
  const handleErrorClose = () => {
    setIsErrorModal(false);
    setOpen(false);
  };
  const handleCaseCompletion = async (caseId, currentStatus) => {
    try {
      const response = await fetch(
        `${API_URL}case_status/cases/${caseId}/completion`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            is_completed: !currentStatus,
          }),
        }
      );

      if (response.ok) {
        Swal.fire({
          title: "Success!",
          text: `Case marked as ${!currentStatus ? "completed" : "incomplete"}`,
          icon: "success",
          confirmButtonColor: "#3085d6",
        });
        setRefresh(!refresh);
      } else {
        throw new Error("Failed to update case status");
      }
    } catch (error) {
      console.error("Error updating case status:", error);
      Swal.fire({
        title: "Error",
        text: "Failed to update case status",
        icon: "error",
        confirmButtonColor: "#3085d6",
      });
    }
  };
  // useEffect(() => {
  //   fetch(`${API_URL}cases/getusers`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setUsers(data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching users:", error);
  //     });
  // }, []);

  const formatDateToUserTimeZone = (utcDateString) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedDate = toZonedTime(utcDateString, userTimeZone);
    return format(zonedDate, "MMMM do, yyyy h:mm:ss a");
  };
  const truncateText = (text, limit) => {
    return text.length > limit ? `${text.slice(0, limit)}...` : text;
  };

  return (
    <>
      {openWorklist ? (
        <SlideArchive
          setopenWorklist={setopenWorklist}
          openWorklist={openWorklist}
          currentCaseId={currentCaseId}
        />
      ) : (
        <div className="worklist-container">
          <div className="worklist-header">
            <div className="header-title">Worklist</div>
            <div className="filterBox">
              <div className="search-worklist">
                <input
                  style={{ border: "0px" }}
                  className="search-bar"
                  type="text"
                  placeholder="Search by hospital no./biopsy no./SI no."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div>
                  <Search />
                </div>
              </div>
              <div className="text-div1">
                From
                <input
                  className="date-worklist"
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <div className="text-div2">
                To
                <input
                  className="date-worklist"
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  style={{ marginLeft: "-20px" }}
                />
              </div>
              <div>
                <select
                  className="specialization"
                  value={specialization}
                  onChange={(e) => setSpecialization(e.target.value)}
                >
                  <option value="">Specialization</option>
                  <option value="specialization1">Specialization 1</option>
                  <option value="specialization2">Specialization 2</option>
                  <option value="specialization3">Specialization 3</option>
                </select>
              </div>
              <div className="create-case" onClick={handleOpenCreate}>
                Create Case
              </div>
            </div>
          </div>

          <div className="worklist-head">
            <div className="create-case-desktop" onClick={() => setOpen(true)}>
              Create Case
            </div>
            <div>
              <select
                className="specialization-desktop"
                value={specialization}
                onChange={(e) => setSpecialization(e.target.value)}
              >
                <option value="">Specialization</option>
                <option value="specialization1">Specialization 1</option>
                <option value="specialization2">Specialization 2</option>
                <option value="specialization3">Specialization 3</option>
              </select>
            </div>
            <div className="text-div2-desktop">
              To
              <input
                className="date-worklist"
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
            <div
              className="delete-btn"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Button
                onClick={handleBatchDelete}
                disabled={!isDeleteActive}
                startIcon={<Delete fontSize="small" />}
                style={{
                  color: isDeleteActive ? "#FB3B42" : "grey",
                  backgroundColor: isDeleteActive ? "" : "transparent",
                  padding: "8px 16px",
                  borderRadius: "4px",
                  textTransform: "none",
                }}
              >
                Delete
              </Button>
            </div>
          </div>

          <div className="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      padding: "0px 0px 5px 0px",
                      fontSize: 12,
                      fontWeight: "600",
                      borderBottom: "none",
                    }}
                  >
                    <Checkbox
                      className="small-checkbox"
                      indeterminate={
                        selectedCases.length > 0 &&
                        selectedCases.length < cases.length
                      }
                      checked={
                        cases.length > 0 &&
                        selectedCases.length === cases.length
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedCases(
                            cases?.map((caseItem) => caseItem.CaseId)
                          );
                          setIsDeleteActive(true);
                        } else {
                          setSelectedCases([]);
                          setIsDeleteActive(false);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell className="table-head">Sl No</TableCell>
                  <TableCell className="table-head">Hospital Name</TableCell>
                  <TableCell className="table-head">Case Name</TableCell>
                  <TableCell className="table-head">Specialization</TableCell>
                  <TableCell className="table-head">Patient Details</TableCell>
                  <TableCell className="table-head">Date</TableCell>
                  <TableCell className="table-head">Total Files</TableCell>
                  <TableCell className="table-head">Total Size</TableCell>
                  <TableCell className="table-head">Priority</TableCell>
                  <TableCell
                    style={{
                      padding: "0px 5px 5px 0px",
                      textAlign: "end",
                      fontSize: 12,
                      fontWeight: "600",
                      borderBottom: "none",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cases?.length > 0 ? (
                  cases.map((row, index) => (
                    <React.Fragment key={row.CaseId}>
                      <TableRow
                        className="table-row"
                        onDoubleClick={() => {
                          setCurrentCaseId(row.CaseId);
                          setopenWorklist(true);
                        }}
                      >
                        <TableCell
                          style={{
                            borderTopLeftRadius: 12,
                            borderBottomLeftRadius: 12,
                          }}
                          className="table-cell"
                        >
                          <Checkbox
                            className="small-checkbox"
                            checked={selectedCases.includes(row.CaseId)}
                            onChange={() => {
                              setSelectedCases((prev) => {
                                const newSelection = prev.includes(row.CaseId)
                                  ? prev.filter((id) => id !== row.CaseId)
                                  : [...prev, row.CaseId];
                                setIsDeleteActive(newSelection.length > 0);
                                return newSelection;
                              });
                            }}
                          />
                        </TableCell>
                        <TableCell className="table-cell">
                          {index + 1 + (page - 1) * 5}
                        </TableCell>
                        <TableCell className="table-cell" style={{overflow:'hidden'}}>
                          {truncateText(row.HospitalName,10)}
                        </TableCell>
                        <TableCell className="table-cell">
                          {truncateText(row.CaseName,10)}
                        </TableCell>
                        <TableCell className="table-cell">
                          {truncateText(row.Specialization,15)}
                        </TableCell>
                        <TableCell className="table-cell">
                          <div>
                            <span className="details">Name</span>:{" "}
                            {truncateText(row.PatientName, 15)}
                          </div>
                          <div>
                            <span className="details">Age:</span> {row.Age}
                          </div>
                          <div>
                            <span className="details">Gender:</span>{" "}
                            {row.Gender}
                          </div>
                          <div>
                            <span className="details">Id:</span>{" "}
                            {truncateText(row.PatientStrId, 10)}
                          </div>
                        </TableCell>
                        <TableCell className="table-cell">
                          {formatDateToUserTimeZone(row.CreatedAt)}
                        </TableCell>
                        <TableCell className="table-cell">
                          {row.Slides}
                        </TableCell>
                        <TableCell className="table-cell">
                          {parseFloat(row.FileSize).toFixed(2) + " Mb"}
                        </TableCell>
                        <TableCell className="table-cell">
                          <div
                            className="priority-level"
                            style={
                              getPriorityLabel(row.Priority.toString()).style
                            }
                          >
                            {getPriorityLabel(row.Priority.toString()).label}
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            borderTopRightRadius: 12,
                            borderBottomRightRadius: 12,
                            textAlign: "end",
                            width: 230,
                          }}
                          className="table-cell"
                        >
                          <IconButton
                            className="iconButton"
                            onClick={() =>
                              handleCaseCompletion(row.CaseId, row.is_completed)
                            }
                          >
                            <VerifiedOutlined
                              className="verifiedIcon"
                              style={{
                                fontSize: "24px",
                                color: row.is_completed ? "#47D273" : "grey",
                              }}
                            />
                          </IconButton>

                          <IconButton
                            onClick={() => handleFlagOpen(row.CaseId)}
                            className="FlagIconButton"
                          >
                            <FlagOutlined
                              className="FlagIcon"
                              style={{
                                fontSize: "16px",
                                color: row.is_flagged ? "#FF4D4D" : "grey",
                              }}
                            />
                          </IconButton>

                          <IconButton
                            onClick={() => handleOpinionOpen(row.CaseId)}
                            className="OpinionIconButton"
                          >
                            <AddCommentOutlined
                              className="OpinionAddCommentIcon"
                              style={{
                                fontSize: "16px",
                                color: row.has_opinion ? " #FAC20A" : "grey",
                              }}
                            />
                          </IconButton>

                          <IconButton
                            className="iconButton"
                            onClick={() => handleDetailOpen(row.CaseId)}
                          >
                            <RemoveRedEyeOutlined
                              className="removeRedEyeIcon"
                              style={{ fontSize: "16px", color: "grey" }}
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => handleOpenEdit(row.CaseId)}
                            className="iconButton"
                          >
                            <EditOutlined
                              className="editIcon"
                              style={{ fontSize: "16px", color: "grey" }}
                            />
                          </IconButton>
                          <IconButton
                            className="iconButton"
                            onClick={() => handleDeleteCase(row.CaseId)}
                          >
                            <DeleteOutline
                              className="deleteIcon"
                              style={{ fontSize: "16px", color: "grey" }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <div style={{ height: "4px" }}></div>
                    </React.Fragment>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={11}
                      align="center"
                      style={{
                        padding: "50px",
                        textAlign: "center",
                        backgroundColor: "#f9f9f9",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "35px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        No Cases Found !
                      </div>
                      <div
                        style={{
                          fontSize: "19px",
                          color: "black",
                          marginTop: "10px",
                        }}
                      >
                        Please add new cases or try refreshing the page.
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              marginTop: "20px",
            }}
          >
            <Stack spacing={2}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={(event, value) => setPage(value)}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
            </Stack>
          </div>

          {open && (
            <CaseModal
              refresh={refresh}
              setRefresh={setRefresh}
              open={open}
              setOpen={setOpen}
            />
          )}

          {shareModal && (
            <ShareModal
              onClose={() => setShareModal(false)}
              shareModal={shareModal}
            />
          )}
          {isFlagModalOpen && selectedCaseForFlag && (
            <FlagModal
              isOpen={isFlagModalOpen}
              onClose={handleFlagClose}
              caseId={selectedCaseForFlag.CaseId}
              flagReason={selectedCaseForFlag.flag_reason || ""}
              onSuccess={() => setRefresh(!refresh)}
            />
          )}

          {isOpinionModalOpen && selectedCase && (
            <OpinionModal
              isOpen={isOpinionModalOpen}
              onClose={handleOpinionClose}
              caseId={selectedCase.CaseId}
              opinion={selectedCase.opinion || ""}
              onSuccess={() => setRefresh(!refresh)}
            />
          )}
          {isDetailModalOpen && setSelectedCaseDetail && (
            <DetailModal
              isOpen={isDetailModalOpen}
              onClose={handleDetailClose}
              caseData={SelectedCaseDetail}
              onSuccess={() => setRefresh(!refresh)}
            />
          )}

          {open && (
            <CaseModal
              open={open}
              setOpen={setOpen}
              setRefresh={setRefresh}
              isEditMode={isEditMode}
              caseData={caseData}
              onSave={handleSave}
              setIsEditMode={setIsEditMode}
            />
          )}
        </div>
      )}
    </>
  );
};

export default WorkList;
