import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Login from "./Views/Login/Login";
import CaseModal from "./Components/CaseModal/CaseModal";
import Navbar from "./Components/Navbar/Navbar";
import SidePanel from "./Components/SidePanel/SidePanel";
import ShareLogin from "./Components/Share/ShareLogin";
import URLRedirectHandler from "./Components/URLRedirectHandler";
import ViewerPage from "./Components/Share/Viewerpage";
import CreateAccount from "./Views/CreateAccount/CreateAccount";
import ResetPassword from "./Views/Login/Resetpassword";

function App() {
  const location = useLocation();

  // Check if the current path should show navbar
  const shouldShowNavbar = () => {
    return (
      location.pathname !== "/" &&
      location.pathname !== "/sharelogin" &&
      location.pathname !== "/viewer" &&
      location.pathname !== "/create-account" &&
      location.pathname !== "/reset-password"
    );
  };

  return (
    <div className="app">
      <URLRedirectHandler />
      {shouldShowNavbar() && <Navbar />}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/home" element={<SidePanel />} />
        <Route path="/sharelogin" element={<ShareLogin />} />
        <Route path="/viewer" element={<ViewerPage />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Routes>
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
