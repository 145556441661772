import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./style.css";
import { API_URL } from "../../env";

const ShareLogin = () => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const shareId = searchParams.get("id");
  // Make the isPublic check case-insensitive and handle both 'true' and 'True'
  const isPublic = searchParams.get("isPublic") === "True";
  console.log("isPublic param:", searchParams.get("isPublic=True"));
  useEffect(() => {
    if (!shareId) {
      navigate("/");
      return;
    }

    // If isPublic is true, navigate directly to viewer
    if (isPublic) {
      fetchPublicShare();
    }
  }, [shareId, isPublic, navigate]);

  const fetchPublicShare = async () => {
    try {
      setLoading(true);
      const url = new URL(`${API_URL}share/share/${shareId}`);

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      // Validate required fields
      if (!data?.annotation_url || !data?.viewer_url) {
        throw new Error("Invalid response: Missing required data");
      }

      // Store data in localStorage
      localStorage.setItem("annotationUrl", data.annotation_url);
      localStorage.setItem("viewerUrl", data.viewer_url);
      localStorage.setItem("slideName", data.slide_name || "Untitled");
      localStorage.setItem("isEditMode", (data.is_edit ?? false).toString());

      // Navigate to the viewer page
      navigate("/viewer", {
        state: {
          annotationUrl: data.annotation_url,
          viewerUrl: data.viewer_url,
          slideName: data.slide_name || "Untitled",
          isEditMode: data.is_edit ?? false,
        },
      });
    } catch (error) {
      setError(error.message || "An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const validateShare = async (shareId, password) => {
    const url = new URL(`${API_URL}share/share/${shareId}`);
    url.searchParams.append("password", password);

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        throw new Error("Invalid password");
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      if (!shareId) {
        throw new Error("Invalid share link");
      }

      const result = await validateShare(shareId, password);

      // Validate required fields
      if (!result?.annotation_url || !result?.viewer_url) {
        throw new Error("Invalid response: Missing required data");
      }

      // Store data in localStorage
      localStorage.setItem("annotationUrl", result.annotation_url);
      localStorage.setItem("viewerUrl", result.viewer_url);
      localStorage.setItem("slideName", result.slide_name || "Untitled");
      localStorage.setItem("isEditMode", (result.is_edit ?? false).toString());

      // Navigate to the viewer page
      navigate("/viewer", {
        state: {
          annotationUrl: result.annotation_url,
          viewerUrl: result.viewer_url,
          slideName: result.slide_name || "Untitled",
          isEditMode: result.is_edit ?? false,
        },
      });
    } catch (err) {
      setError(err.message || "An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="login-container">
        <div className="login-card">
          <h1 className="login-title">Loading...</h1>
          {error && <div className="error-message">{error}</div>}
        </div>
      </div>
    );
  }

  if (isPublic) {
    return null; // Return null while fetching public share data as it will redirect
  }

  return (
    <div className="login-container">
      <div className="login-card">
        <h1 className="login-title">Secure Access</h1>
        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              className="password-input"
              disabled={loading}
              required
            />
          </div>

          {error && <div className="error-message">{error}</div>}

          <button
            type="submit"
            className="login-button"
            disabled={loading || !password.trim()}
          >
            {loading ? "Validating..." : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ShareLogin;
