import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import "./style.css";
import { API_URL } from "../../env";

function FlagModal({
  isOpen,
  onClose,
  caseId,
  flagReason: initialFlagReason,
  onSuccess,
  isSlide,
}) {

  const [reason, setReason] = useState(initialFlagReason || "");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (isOpen) {
      setReason(initialFlagReason || "");
    }
  }, [isOpen, initialFlagReason]);

  if (!isOpen) return null;

  const handleSubmit = async () => {
    const trimmedReason = reason.trim();

    setIsSubmitting(true);
    setError("");

    try {
      const endpoint = isSlide
        ? `${API_URL}slide_status/slides/${caseId}/flag`
        : `${API_URL}case_status/cases/${caseId}/flag`;

      const response = await fetch(endpoint, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          is_flagged: !!trimmedReason,
          flag_reason: trimmedReason,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.message ||
            `Failed to update the ${isSlide ? "slide" : "case"} flag status.`
        );
      }

      const data = await response.json();

      Swal.fire({
        icon: "success",
        title: trimmedReason
          ? `${isSlide ? "Slide" : "Case"} Flagged Successfully!`
          : "Flag Removed Successfully!",
        text: trimmedReason
          ? `The ${
              isSlide ? "slide" : "case"
            } has been flagged with the provided reason.`
          : `The ${isSlide ? "slide" : "case"} flag has been removed.`,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });

      if (onSuccess) {
        onSuccess();
      }

      onClose();
    } catch (error) {
      const errorMessage =
        error.message ||
        "There was an error updating the flag status. Please try again.";
      setError(errorMessage);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errorMessage,
        confirmButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setReason("");
    setError("");
    onClose();
  };

  const handleOverlayClick = (e) => {
    if (e.target.className === "flag-modal-overlay") {
      handleClose();
    }
  };

  return (
    <div className="flag-modal-overlay" onClick={handleOverlayClick}>
      <div className="flag-modal">
        <div className="modal-header">
          <h3 style={{marginTop:"2px",marginLeft:"3px"}}>Why do you want to flag this {isSlide ? "Slide" : "Case"}</h3>
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: -10,
              right: -10,
              width: 25,
              height: 25,
              borderRadius: "50%",
              backgroundColor: "white",
              color: "black",
              padding: 0,
              boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div className="modal-content">
        <p style={{marginLeft:"3px"}}>Please provide a reason for flagging this case.</p>

          {error && <div className="error-message">{error}</div>}

          <textarea
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            placeholder="Enter your reason here..."
            className="reason-textarea"
            disabled={isSubmitting}
          />
        </div>

        <div className="modal-actions">
          <button
            className="cancel-button"
            onClick={handleClose}
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            className="submit-button"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default FlagModal;
