import React, { useState,useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import "./style.css";
import { API_URL } from "../../env";

function OpinionModal({ isOpen, onClose, caseId, opinion: initialOpinion, onSuccess }) {
  const [opinion, setOpinion] = useState(initialOpinion || ""); 
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setOpinion(initialOpinion || "");
    }
  }, [isOpen, initialOpinion]);

  if (!isOpen) return null;

  const handleSubmit = async () => {
    if (!opinion.trim()) {
      setIsSubmitting(true); 
  
      try {
        const response = await fetch(
          `${API_URL}case_status/cases/${caseId}/opinion`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              has_opinion: false, 
              opinion: "",
            }),
          }
        );
  
        const data = await response.json();
  
        if (response.ok) {
          await Swal.fire({
            title: "Success!",
            text: "Your opinion has been removed.",
            icon: "success",
            confirmButtonColor: "#3085d6",
          });
  
          if (onSuccess) {
            onSuccess(); 
          }
  
          setOpinion(""); 
          onClose(); 
        } else {
          throw new Error(data.message || "Failed to remove opinion");
        }
      } catch (error) {
        console.error("Error removing opinion:", error);
        Swal.fire({
          title: "Error",
          text:
            error.message || "There was an error removing your opinion. Please try again.",
          icon: "error",
          confirmButtonColor: "#3085d6",
        });
      } finally {
        setIsSubmitting(false); 
      }
    } else {
      
      setIsSubmitting(true);
  
      try {
        const response = await fetch(
          `${API_URL}case_status/cases/${caseId}/opinion`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              has_opinion: true,  
              opinion: opinion.trim(),
            }),
          }
        );
  
        const data = await response.json();
  
        if (response.ok) {
          await Swal.fire({
            title: "Success!",
            text: "Your opinion has been successfully submitted!",
            icon: "success",
            confirmButtonColor: "#3085d6",
          });
  
          if (onSuccess) {
            onSuccess();
          }
  
          setOpinion(""); 
          onClose(); 
        } else {
          throw new Error(data.message || "Failed to submit opinion");
        }
      } catch (error) {
        console.error("Error submitting opinion:", error);
        Swal.fire({
          title: "Error",
          text:
            error.message ||
            "There was an error submitting your opinion. Please try again.",
          icon: "error",
          confirmButtonColor: "#3085d6",
        });
      } finally {
        setIsSubmitting(false); 
      }
    }
  };
  
  

  const handleClose = () => {
    setOpinion("");
    onClose();
  };

  return (
    <div
      className="opinion-modal-overlay"
      onClick={(e) => {
        if (e.target.className === "opinion-modal-overlay") handleClose();
      }}
    >
      <div className="opinion-modal">
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: -10,
            right: -10,
            width: 25,
            height: 25,
            borderRadius: "50%",
            backgroundColor: "white",
            color: "black",
            padding: 0,
            boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
          }}
        >
          <CloseIcon />
        </IconButton>

        <p className="title">What is Your Opinion?</p>
        <p className="reason-title" style={{marginTop:"-12px"}}>Let us know your thoughts</p>

        <div className="input-group">
          <textarea
            value={opinion}
            onChange={(e) => setOpinion(e.target.value)}
            placeholder="Enter your opinion here..."
            className="reason-textarea"
            disabled={isSubmitting}
          />
        </div>

        <div className="modal-actions">
          <button
            className="cancel-button"
            onClick={handleClose}
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            className="submit-button"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default OpinionModal;